























































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { ElForm } from 'element-ui/types/form';
import {
  getHealthList,
  getDetailCheck,
} from '@/serve/evaluatingBefore/evaluatingRecords';
import { getTenantData } from '@/serve/medicalAppointment/institutionManage';
interface Data {
  current?: number;
  size?: number;
  [propName: string]: any;
}
@Component({
  name: 'HealthyList',
})
export default class HealthyList extends Vue {
  public currentPage4 = 1;
  public dialogFormVisible = false;
  public form = {
    checkerName: '',
    checkerIdCard: '',
    current: 1,
    size: 10,
    quesStartTime: '',
    quesEndTime: '',
    quesType: '健康测评',
    tenantId: '',
    tenantName:""
  };
  public date = [];
  public dialogForm = [];
  public detailList = [];
  public isAdmin = sessionStorage.getItem('isAdmin');
  public tenantName = '';
  public restaurants = [];
  public async created() {
    this.getList();
  }
  public mounted() {
    this.getTenantData();
  }
  /**获取全部租户 */
  public async getTenantData(): Promise<void> {
    const res = await getTenantData({ size: 100000, current: 1 });
    this.restaurants = res.data.records;
  }
  public handleSelect(e: any) {
    if (Object.keys(e).length > 0) this.form.tenantId = e.tId;
    else this.form.tenantId = '';
  }
  public async querySearch(queryString: any, cb: any) {
    const res = await getTenantData({
      size: 100,
      current: 1,
      name: queryString,
    });
    const restaurants = res.data.records;
    const newArr: any = [];
    restaurants.forEach((x: any) => {
      if (x.name.includes(queryString)) {
        newArr.push({ value: x.name, tId: x.id });
      }
    });
    // 调用 callback 返回建议列表的数据
    cb(newArr);
  }
  public tableData = [
    {
      recdors: [
        {
          jkglCheckerVO: [
            {
              checkerName: '',
              checkerPhone: '',
              checkerIdCard: '',
              updateTime: '',
            },
          ],
        },
      ],
    },
  ];
  public async getList() {
    // 如果form的数据有'' 则赋值为null 由于后端也会查询''类型的值
    for (const key in this.form) {
      if ((this.form as Data)[key] === '') {
        (this.form as Data)[key] = null;
      }
    }
    const res = await getHealthList(this.form);
    this.tableData = await res.data;
  }
  // 筛选功能
  public onSubmit() {
    if (this.date) {
      // 绑定的date的值 然后是个数组赋值给form
      this.form.quesStartTime = this.date[0];
      this.form.quesEndTime = this.date[1];
    } else {
      this.form.quesStartTime = '';
      this.form.quesEndTime = '';
    }
    // 点击筛选后 初始化值
    this.currentPage4 = 1;
    this.form.size = 10;
    this.form.current = 1;
    this.getList();
  }
  // 重置功能
  public resetForm(formName: string) {
    (this.$refs[formName] as ElForm).resetFields();
    this.currentPage4 = 1;  
    this.form.tenantName=''
    this.clearName()
    this.getList();
    this.date=[];
  
  }
  // 详情按钮点击
  public async readMore(index: number, id: number) {
    this.dialogFormVisible = true;
    const res = await getDetailCheck(id);
    this.detailList = res.data;
  }
  // 点击左右箭头
  public handleSizeChange(val: any): void {
    this.form.size = val;
    this.getList();
  }
  // 点击页码
  public handleCurrentChange(val: any): void {
    this.form.current = val;
    this.getList();
  }
  /** 清空机构名称事件 */
  public clearName() {
    this.form.tenantId = '';
  }
}
