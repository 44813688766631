import request from '../../utils/request'

//获取评估接口分页
export function getHealthList(data: any): Promise<any> {
  return request({
    method: 'GET',
    data,
    type: 'params',
    url: 'manage/chckerQuesRela/list',
  })
}

// 获取评估详情接口
export function getDetailCheck(data: number): Promise<any> {
  return request({
    method: 'GET',
    url: 'manage/chckerQuesRela/detail/' + data,
  })
}